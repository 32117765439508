import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import jwt_decode from "jwt-decode";
import partnerApi from "src/api/partner";
import useAlert from "src/hooks/useAlert";

const TOKEN_ID = "x-partner-access-token";

interface AuthState {
  currentPartner: number | null;
  redirectTo?: string;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (data: any) => Promise<void>;
  setRedirection: (url: string) => void;
}

const initialAuthState: AuthState = {
  currentPartner: null,
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    currentPartner: number | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    currentPartner: number | null;
  };
};

type LogoutAction = {
  type: "LOGOUT";
  payload?: {};
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    currentPartner: number | null;
  };
};

type RedirectAction = {
  type: "REDIRECT";
  payload: string;
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | RedirectAction;

const setSession = (token: string | null): void => {
  if (token) {
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem(TOKEN_ID, token);
  } else {
    localStorage.setItem(TOKEN_ID, "");
    // delete axios.defaults.headers.common.Authorization;
  }
};

const decodeToken = (token: string | null) => {
  if (token) {
    const decoded: any = jwt_decode(token);
    const expireAt = new Date(decoded.exp * 1000);
    const currentTime = new Date();
    if (expireAt > currentTime) {
      return decoded.id;
    }
  }
  return null;
};

const handlers = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { currentPartner } = action.payload;
    return {
      ...state,
      currentPartner,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction) => {
    const { currentPartner } = action.payload;
    return {
      ...state,
      currentPartner,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    currentPartner: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction) => {
    const { currentPartner } = action.payload;
    return {
      ...state,
      currentPartner,
    };
  },
  REDIRECT: (state: AuthState, action: RedirectAction) => {
    return {
      ...state,
      redirectTo: action.payload,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setRedirection: () => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { setAlert } = useAlert();

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem(TOKEN_ID);
        const currentPartner = decodeToken(token);

        if (currentPartner) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentPartner,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentPartner: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            currentPartner: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const token = await partnerApi.login(email, password);
      const currentPartner = decodeToken(token);

      setSession(token);

      dispatch({
        type: "LOGIN",
        payload: {
          currentPartner,
        },
      });

      // navigate(location.pathname === '/' ? '/dashboard' : location.pathname);
    } catch (err: any) {
      console.error("App login: problem logging", err);
      setAlert({
        display: true,
        message: err?.message || "Unable to login",
        type: "error",
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (data: any) => {
    try {
      const token = await partnerApi.register(data);
      const currentPartner = decodeToken(token);

      setSession(token);

      dispatch({
        type: "REGISTER",
        payload: {
          currentPartner,
        },
      });
    } catch (err: any) {
      console.error("App register: problem registering", err);
      setAlert({
        display: true,
        message: err?.message || "Unable to register",
        type: "error",
      });
    }
  };

  const setRedirection = useCallback((url: string) => {
    dispatch({
      type: "REDIRECT",
      payload: url,
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        setRedirection,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
