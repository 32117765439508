import { ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames";
interface TextInputProps {
  name: string;
  label?: string | ReactNode;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
    disabled?: boolean;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
}

const TextInput = ({
  name,
  label,
  type,
  placeholder,
  value,
  error,
  labelProps,
  inputProps,
  errorProps,
  onChange,
  disabled = false,
}: TextInputProps) => {
  const labelClassName = classNames(
    "block uppercase text-card-dark  text-xs font-extrabold mb-1 text-left px-1",
    labelProps?.className
  );

  const inputClassName = classNames(
    {
      "border-2 border-error px-3 py-3 placeholder-gray-400 text-text-dark dark:text-text-light font-bold placeholder:font-normal bg-white rounded text-sm shadow focus:outline-none w-full":
        !!error,
      "border-0 px-3 py-3 placeholder-gray-400 text-text-dark dark:text-text-light font-bold placeholder:font-normal bg-white rounded text-xm shadow focus:outline-none focus:ring ring-secondary w-full":
        !error,
    },
    inputProps?.className
  );

  const errorClassName = classNames(
    "text-error text-xs md:text-sm font-semibold italic ml-1",
    errorProps?.className
  );

  return (
    <>
      {label && (
        <label htmlFor={name} {...labelProps} className={labelClassName}>
          {label}
        </label>
      )}
      <input
        {...inputProps}
        type={type || "text"}
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
      />
      {error && (
        <p {...errorProps} className={errorClassName}>
          {error}
        </p>
      )}
    </>
  );
};

export default TextInput;
