import React, { useState } from "react";
import useModal from "src/hooks/useModal";
import { motion, AnimatePresence } from "framer-motion";
import ButtonBlock from "./ButtonBlock";
// import { TeamPageData } from "src/global/interfaces/TeamPageData";

interface ReactivateTeamModalProps {
  /* team: TeamPageData;
  fetchTeam: () => void; */
  onSubmit: (v: number) => void;
}

const ReactivateTeamModal = ({ onSubmit }: ReactivateTeamModalProps) => {
  const { setShowModal } = useModal();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const [isTrial, setIsTrial] = useState<boolean>(false);

  const [trialLength, setTrialLength] = useState<number>(30);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setTrialLength(value);
  };

  const handleSubmit = () => {
    setOnSubmitting(true);
    try {
      if (typeof onSubmit === "function") {
        onSubmit(isTrial ? trialLength : 0);
      }
      // fetchTeam();
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <div className="mb-4 flex items-center justify-center gap-4">
          <div
            className="flex items-center gap-2"
            onClick={() => setIsTrial(false)}
          >
            <input
              type="radio"
              name="purchased"
              className="radio-secondary radio"
              checked={!isTrial}
            />
            <label htmlFor="purchased" className="text-zinc-400">
              Charge Immediately
            </label>
          </div>
          <div
            className="flex items-center gap-2"
            onClick={() => setIsTrial(true)}
          >
            <input
              type="radio"
              name="subscription"
              className="radio-secondary radio"
              checked={isTrial}
            />
            <label htmlFor="subscription" className="text-zinc-400">
              Trial
            </label>
          </div>
        </div>
        <AnimatePresence>
          {isTrial && (
            <motion.div
              layout
              initial={{ opacity: 0.8 }}
              transition={{ duration: 0.15 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0.5 }}
              className="flex items-center justify-center gap-2"
            >
              <div className="mb-4">
                <input
                  type="number"
                  className="input-bordered input w-16"
                  onChange={handleChange}
                  value={trialLength}
                />
              </div>
              <div>
                <p className="text-zinc-400">days</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="mt-4">
          <ButtonBlock
            submitting={onSubmitting}
            handleSubmit={handleSubmit}
            onCancel={handleCancel}
            submitLabel={"Reactivate"}
            disabled={onSubmitting}
          />
        </div>
      </div>
    </>
  );
};

export default ReactivateTeamModal;
