/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import usePartnerContext from "src/hooks/private/usePartnerContext";
import { useLocation } from "react-router-dom";
import useAuth from "../useAuth";

const usePartnerRedirect = () => {
  const { partner } = usePartnerContext();

  const location = useLocation();

  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  const { logout } = useAuth();

  const permissions = [
    { prop: "canTeams", route: "/teams" },
    { prop: "canAdmins", route: "/admins" },
    { prop: "canReports", route: "/reports" },
  ];

  useEffect(() => {
    if (partner && partner?.securityLevel !== 1) {
      const currentRouteAllowed = permissions.some(
        (permission) =>
          location.pathname.startsWith(permission.route) &&
          (partner as any)[permission.prop]
      );

      if (!currentRouteAllowed) {
        const accessibleRoute = permissions.find(
          (permission) => (partner as any)[permission.prop]
        );
        if (accessibleRoute) {
          setRedirectPath(accessibleRoute.route);
        } else {
          logout();
        }
      }
    }
  }, [partner, location.pathname, logout]);

  return redirectPath;
};

export default usePartnerRedirect;
