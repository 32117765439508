import React, { useState } from "react";
import { parseMonthDay } from "src/helpers/parseEpochs";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import UserAvatar from "../buttons/UserAvatar";
import TicketStatus from "../fields/TicketStatus";
import TicketDrawer from "./SupportTicketDrawer";
import ToggleSupportTicketDetails from "./ToggleSupportTicketDetails";
import partnerApi from "src/api/partner";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "../buttons/CircularActionButton";

interface TeamSupportTicketRowProps {
  supportTicket: any;
  fetchSupportTickets: () => void;
}

const TeamSupportTicketRow = ({
  supportTicket,
  fetchSupportTickets,
}: TeamSupportTicketRowProps) => {
  const { setAlert } = useAlert();

  const { isMobile } = useViewport();

  const { setShowModal, openModalWith } = useModal();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {
    id,
    subject,
    message,
    createdAt,
    userID,
    teamID,
    email,
    firstName,
    lastName,
    fullName,
    isLeader,
  } = supportTicket;

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDelete = (supportTicketID: number) => {
    const handleDeleteSubmit = async (id: number) => {
      try {
        setShowModal(false);
        const result = await partnerApi.deleteSupportTicket(id);
        if (result) {
          setAlert({
            display: true,
            message: "Support Ticket deleted",
            type: "success",
          });
        }
      } catch (err: any) {
        setAlert({
          display: true,
          message: "There was an error deleting your support ticket",
          type: "error",
        });
      } finally {
        return fetchSupportTickets();
      }
    };

    openModalWith({
      title: "Delete Support Ticket",
      body: "Are you sure you want to delete this ticket?",
      onSubmit: () => handleDeleteSubmit(supportTicketID),
      submitLabel: "Delete",
      submitIcon: faTrash,
    });
  };

  return (
    <>
      <tr className="bg-card-light dark:bg-card-dark">
        <td className="w-12 bg-card-light text-left font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
          {id}
        </td>
        <td className="bg-card-light text-left dark:bg-card-dark">
          <div className="flex w-full items-center justify-start">
            <TicketStatus
              ticket={supportTicket}
              fetchSupportTickets={fetchSupportTickets}
            />
          </div>
        </td>
        <td className="bg-card-light text-left font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
          {parseMonthDay(createdAt)}
        </td>
        <td className="bg-card-light text-left font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
          <UserAvatar
            user={{
              id: userID,
              teamID: teamID,
              email: email,
              firstName: firstName,
              lastName: lastName,
              fullName: fullName,
              isLeader: isLeader,
            }}
          />
        </td>
        <td className="flex items-center gap-2 bg-card-light text-left dark:bg-card-dark">
          <CircularActionButton
            icon={faTrash}
            onClick={() => handleDelete(id)}
          />
          <ToggleSupportTicketDetails
            onClick={handleDrawer}
            isToggled={isDrawerOpen}
          />
        </td>
      </tr>
      <tr
        className={
          isDrawerOpen
            ? "relative bg-card-light transition-all duration-300 dark:bg-card-dark "
            : "absolute h-0 bg-card-light  duration-300 dark:bg-card-dark "
        }
      >
        {isDrawerOpen && (
          <td
            colSpan={isMobile ? 4 : 6}
            className="overflow-x-hidden border-x bg-back-light dark:border-x dark:border-card-dark dark:bg-back-dark"
            style={{
              overflowWrap: "break-word",
            }}
          >
            <TicketDrawer subject={subject} message={message} />
          </td>
        )}
      </tr>
    </>
  );
};

export default TeamSupportTicketRow;
