import React, {
  useCallback,
  useEffect,
  PropsWithChildren,
  createContext,
} from "react";
import { subscriptionApi } from "src/api";
import { useState } from "react";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import {
  Subscription,
  Feature,
  SubscriptionFeatures,
  AppSubscriptionsAndFeatures,
} from "src/interfaces/subscriptions";
import usePartnerContext from "src/hooks/private/usePartnerContext";

interface SubscriptionContextInterface {
  appSubscriptions: Subscription[] | null | undefined;
  // features: Feature[] | null | undefined;
  // subscriptionFeatures: SubscriptionFeatures | null | undefined;
  // isLoadingSubscriptionFeatures: boolean;
  // appSubscriptionsAndFeatures: AppSubscriptionsAndFeatures[] | null | undefined;
  fetchAppSubscriptions: () => void;
  // fetchFeatures: () => void;
  // fetchSubscriptionFeatures: () => void;
  // fetchAppSubscriptionsAndFeatures: () => void;
}

type CombinedSubscription = {
  subscription: Subscription;
  subscriptionFeatures: SubscriptionFeatures;
};

const subscriptionContextDefaults: SubscriptionContextInterface = {
  appSubscriptions: null,
  // features: null,
  // subscriptionFeatures: null,
  // isLoadingSubscriptionFeatures: true,
  // appSubscriptionsAndFeatures: null,
  fetchAppSubscriptions: () => {},
  // fetchFeatures: () => {},
  // fetchSubscriptionFeatures: () => {},
  // fetchAppSubscriptionsAndFeatures: () => {},
};

const SubscriptionContext = createContext<SubscriptionContextInterface>(
  subscriptionContextDefaults
);

export const SubscriptionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [appSubscriptions, setAppSubscriptions] = useState<Subscription[]>([]);
  // const [features, setFeatures] = useState<Feature[]>([]);
  // const [subscriptionFeatures, setSubscriptionFeatures] =
  //   useState<SubscriptionFeatures>();
  // const [isLoadingSubscriptionFeatures, setIsLoadingSubscriptionFeatures] =
  //   useState<boolean>(true);
  // const [appSubscriptionsAndFeatures, setAppSubscriptionsAndFeatures] =
  //   useState<AppSubscriptionsAndFeatures[]>();

  const { handleError } = useErrorHandler();

  const { partner } = usePartnerContext();

  const fetchAppSubscriptions = useCallback(async () => {
    try {
      if (partner) {
        const appSubscriptionsData = await subscriptionApi.getAllSubscriptions(
          partner?.appID
        );
        setAppSubscriptions(appSubscriptionsData);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, partner]);

  // const fetchFeatures = useCallback(async () => {
  //   try {
  //     const featuresData = await subscriptionApi.getFeatures();
  //     setFeatures(featuresData);
  //   } catch (err) {
  //     handleError(err);
  //   }
  // }, [handleError]);

  // const fetchSubscriptionFeatures = useCallback(async () => {
  //   setIsLoadingSubscriptionFeatures(true);
  //   try {
  //     const subscriptionFeaturesData =
  //       await subscriptionApi.getSubscriptionFeatures();

  //     // Converting this to an object for easier use;
  //     const featuresObject: SubscriptionFeatures =
  //       subscriptionFeaturesData.reduce((obj: any, feature: any) => {
  //         obj[feature.name] = feature;
  //         return obj;
  //       }, {});

  //     setSubscriptionFeatures(featuresObject);
  //   } catch (err) {
  //     handleError(err);
  //   } finally {
  //     setIsLoadingSubscriptionFeatures(false);
  //   }
  // }, [handleError]);

  // const fetchAppSubscriptionsAndFeatures = useCallback(async () => {
  //   try {
  //     const appSubscriptionsData = await subscriptionApi.getAllSubscriptions();

  //     // Fetch all features at once
  //     const allFeaturesData =
  //       await subscriptionApi.getAppSubscriptionFeatures();

  //     const appSubscriptionsWithFeatures: CombinedSubscription[] = [];

  //     for (const subscription of appSubscriptionsData) {
  //       const appSubscriptionFeaturesData = allFeaturesData
  //         .filter((feature) => feature.subscriptionID === subscription.id)
  //         .sort((a, b) => a.featureID - b.featureID);

  //       const featuresObject: SubscriptionFeatures =
  //         appSubscriptionFeaturesData.reduce((obj: any, feature) => {
  //           obj[feature.id] = feature;
  //           return obj;
  //         }, {});

  //       appSubscriptionsWithFeatures.push({
  //         subscription,
  //         subscriptionFeatures: featuresObject,
  //       });
  //     }

  //     appSubscriptionsWithFeatures.sort(
  //       (a, b) => a.subscription.sortOrder - b.subscription.sortOrder
  //     );

  //     setAppSubscriptionsAndFeatures(appSubscriptionsWithFeatures);
  //   } catch (err) {
  //     handleError(err);
  //   }
  // }, [handleError ]);

  useEffect(() => {
    fetchAppSubscriptions();
    // fetchFeatures();
    // fetchSubscriptionFeatures();
    // fetchAppSubscriptionsAndFeatures();
  }, [
    // fetchFeatures,
    // fetchSubscriptionFeatures,
    fetchAppSubscriptions,
    // fetchAppSubscriptionsAndFeatures,
  ]);

  return (
    <SubscriptionContext.Provider
      value={{
        appSubscriptions,
        // features,
        // subscriptionFeatures,
        // isLoadingSubscriptionFeatures,
        // appSubscriptionsAndFeatures,
        fetchAppSubscriptions,
        // fetchFeatures,
        // fetchSubscriptionFeatures,
        // fetchAppSubscriptionsAndFeatures,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
