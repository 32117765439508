import { useState } from "react";
import "./Login.css";
import useAuth from "src/hooks/useAuth";
import TextInput from "src/components/fields/TextInput";
import AuthLayout from "src/layouts/AuthLayout";
import { regexEmail } from "src/helpers/constants";
import AuthButton from "src/components/buttons/AuthButton";
import PasswordInput from "src/components/fields/PasswordInput";
import useTheme from "src/hooks/useTheme";

const Login = () => {
  const { tailwindTheme } = useTheme();

  const initialFormState = {
    email: "",
    password: "",
  };

  const intialFormErrors: {
    email: string;
    password: string;
  } = {
    email: "",
    password: "",
  };

  interface FormErrors {
    email: string | null;
    password: string | null;
  }

  const [formData, setFormData] = useState(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(intialFormErrors);

  const { login } = useAuth();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate: any = (data: any) => {
    const errors: {
      email?: string;
      password?: string;
    } = {};
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    if (!data.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.email || errors.password) {
      return setFormErrors(errors);
    } else {
      const { email, password } = formData;
      setSubmitting(true);
      await login(email, password);
      setSubmitting(false);
    }
  }

  return (
    <AuthLayout title="SendFuse Partner">
      <form onSubmit={handleSubmit}>
        <div className="relative mb-3 w-full">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            error={formErrors.email}
            inputProps={{ className: "text-black dark:text-black" }}
          />
        </div>

        <div className="relative mb-3 w-full">
          <PasswordInput
            label="Password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            error={formErrors.password}
          />
        </div>
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              id="customCheckLogin"
              type="checkbox"
              className="form-checkbox checkbox-accent checkbox ml-1 h-5 w-5 rounded border-0 bg-card-light "
            />
            <span className="ml-2 select-none text-sm font-semibold  text-card-dark">
              Remember Me
            </span>
          </label>
        </div>

        <div className="mt-6 text-center">
          <AuthButton
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
            loaderProps={{ fill: tailwindTheme.theme2 }}
          >
            Login
          </AuthButton>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
