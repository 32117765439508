import React, { useEffect, useState } from "react";
import usePartnerContext from "src/hooks/private/usePartnerContext";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import useAlert from "src/hooks/useAlert";
import NewPartnerModal from "src/components/modals/NewAdminModal";
import EditPartnerModal from "src/components/modals/EditAdminModal";
import { Navigate } from "react-router-dom";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "src/components/buttons/CircularActionButton";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Partner } from "src/interfaces/partner";
import { partnerApi } from "src/api";
import TableFooter from "src/components/table/TableFooter";

const Admins = () => {
  const { partner } = usePartnerContext();

  const { isMobile } = useViewport();

  const { openModalWith, setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [users, setUsers] = useState<Partner[]>([]);

  const fetchUsers = async () => {
    const resp = await partnerApi.getPartners();
    setUsers(resp);
  };

  useEffect(() => {
    fetchUsers();
  }, [partner]);

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const handleNewAdmin = () => {
    openModalWith({
      title: "Invite New Admin",
      body: <NewPartnerModal fetchAdmins={fetchUsers} />,
      hideButtons: true,
    });
  };

  const handleEditPartner = (partnerToEdit: Partner) => {
    if (partner && partner.id === partnerToEdit.id) {
      openModalWith({
        title: "Error",
        body: <p>You cannot edit yourself as an admin.</p>,
        hideButtons: true,
      });
    } else {
      openModalWith({
        title: "Edit Admin",
        body: <EditPartnerModal admin={partnerToEdit} fetchPartners={fetchUsers} />,
        hideButtons: true,
      });
    }
  };

  const handleDeletePartnerSubmit = async (partnerID: number) => {
    setShowModal(false);
    try {
      await partnerApi.deletePartner(partnerID);
      setAlert({
        display: true,
        type: "success",
        message: "Admin was permanently deleted",
      });
    } catch (e: any) {
      console.log(e);
      setAlert({
        display: true,
        type: "error",
        message: e.message || "There was an error deleting this admin",
      });
    } finally {
      fetchUsers();
    }
  };

  const handleDeletePartner = (partnerID: number) => {
    if (partner && partnerID === partner.id) {
      openModalWith({
        title: "Error",
        body: <p>You cannot delete yourself as an admin.</p>,
        hideButtons: true,
      });
    } else {
      openModalWith({
        title: "Delete Admin",
        body: <p>Are you sure you want to permanently delete this admin?</p>,
        onSubmit: () => handleDeletePartnerSubmit(partnerID),
        submitLabel: "Delete",
      });
    }
  };

  return (
    <>
      <div>
        <button
          className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
          onClick={handleNewAdmin}
        >
          + New Admin
        </button>
      </div>
      <table
        className={
          isMobile
            ? "table-compact block table w-full shadow-lg"
            : "block table w-full shadow-lg"
        }
      >
        <thead className="">
          <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
            <th className="sticky top-0 rounded-tl-lg bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Name
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Email
            </th>
            <th className="sticky top-0 bg-card-light text-xs dark:bg-card-dark sm:text-sm">
              Security Level
            </th>
            <th className="sticky top-0 rounded-tr-lg bg-card-light text-center text-xs dark:bg-card-dark sm:text-sm">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((_user: Partner) => (
              <tr key={_user.id}>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  <button
                    className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                    onClick={() =>
                      copyTextToClipboard(
                        `${_user.firstName} ${_user.lastName}`
                      )
                    }
                  >
                    {_user.firstName + " " + _user.lastName}
                  </button>
                </td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  <button
                    className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                    onClick={() => copyTextToClipboard(_user.email)}
                  >
                    {_user.email}
                  </button>
                </td>
                <td className="bg-card-light text-left text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                  {_user.securityLevel === 1 ? "Owner" : "Custom"}
                </td>
                <td className="bg-card-light dark:bg-card-dark">
                  {_user.id !== partner?.id && (
                    <div className="flex items-center justify-center gap-2 bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light sm:text-sm">
                      <CircularActionButton icon={faPenToSquare} onClick={() => handleEditPartner(_user)} />
                      <CircularActionButton
                        icon={faTrash}
                        onClick={() => handleDeletePartner(_user.id)}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
            <TableFooter colSpan={4} />
        </tbody>
      </table>
    </>
  );
};

export default Admins;
