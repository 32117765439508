import { TeamPageData } from "src/interfaces/TeamPageData";
import ActionPallette from "../cards/ActionPallette";
import TeamInfo from "../cards/TeamInfo";
import TeamEventsTable from "./TeamEventsTable";
import { useState, useEffect } from "react";
// import NotesTable from "../notes/NotesTable";
import partnerApi from "src/api/partner";
// import KeapCard from "../cards/KeapCard";
// import RealeflowCard from "../cards/RealeflowCard";
// import DashCard from "../cards/DashCard";

interface AccountTabProps {
  team: TeamPageData;
  fetchTeam: () => void;
  handleActive: (title: string) => void;
}

const AccountTab = ({ team, fetchTeam, handleActive }: AccountTabProps) => {
  const { teamID } = team.user;

  // const [notes, setNotes] = useState([]);

  // const [dealioSitePlanIDGlobal, setDealioSitePlanIDGlobal] = useState<
  //   number | null
  // >(null);

  // const [dashUpgradeTagInKeapGlobal, setDashUpgradeTagInKeapGlobal] =
  //   useState<boolean>(false);

  const fetchNotes = async () => {
    // if (teamID) {
    //   const response = await partnerApi.getTeamNotes(teamID);
    //   setNotes(response);
    // }
  };

  useEffect(() => {
    // fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID]);

  return (
    <>
      <div className="mb-2 flex w-full gap-1">
        <div className="w-1/2">
          <div className="mb-1">
            <TeamInfo
              team={team}
              fetchTeam={fetchTeam}
              handleActive={handleActive}
              fetchNotes={fetchNotes}
            />
          </div>
          <div className="mb-1">
            {/* <NotesTable team={team} notes={notes} fetchNotes={fetchNotes} /> */}
          </div>
          <TeamEventsTable team={team} />
        </div>
        <div className="w-1/2">
          <div className="mb-1">
            <ActionPallette team={team} fetchTeam={fetchTeam} />
          </div>
          <div className="mb-1">
            {/* <DashCard
              team={team}
              fetchTeam={fetchTeam}
              dealioSitePlanIDGlobal={dealioSitePlanIDGlobal}
              dashUpgradeTagInKeapGlobal={dashUpgradeTagInKeapGlobal}
            /> */}
          </div>
          <div className="mb-1">
            {/* <KeapCard
              team={team}
              fetchTeam={fetchTeam}
              dealioSitePlanIDGlobal={dealioSitePlanIDGlobal}
              setDashUpgradeTagInKeapGlobal={setDashUpgradeTagInKeapGlobal}
            /> */}
          </div>
          <div className="mb-1">
            {/* <RealeflowCard
              team={team}
              fetchTeam={fetchTeam}
              dealioSitePlanIDGlobal={dealioSitePlanIDGlobal}
              setDealioSitePlanIDGlobal={setDealioSitePlanIDGlobal}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTab;
