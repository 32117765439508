import { Navigate } from "react-router-dom";
import ActionStatistics from "src/components/dashboard/ActionStatsChart";
import AverageOfferAmount from "src/components/dashboard/AverageOfferAmount";
import ChurnChart from "src/components/dashboard/ChurnChart";
import DashChart from "src/components/dashboard/DashChart";
import RevenueTypeDoughnutChart from "src/components/dashboard/RevenueTypeDoughnutChart";
// import SubscriptionDoughnutChart from "src/components/dashboard/SubscriptionDoughnutChart";


const Dashboard = () => {

  return (
    <>
      <div>
        <div className="mb-1 flex w-full gap-1">
          <div
            style={{
              width: "39%",
            }}
            className=""
          >
            {/* <RevenueTypeDoughnutChart /> */}
          </div>
          <div
          // style={{
          //   width: "39%",
          // }}
          // className=""
          >
            {/* <SubscriptionDoughnutChart /> */}
            {/* <DashChart /> */}
          </div>
          <div
          // style={{
          //   width: "22%",
          // }}
          // className=""
          >
            {/* <AverageOfferAmount /> */}
          </div>
        </div>
        <div className="mb-1 w-full">
          <ActionStatistics />
        </div>
        <div className="w-full">{/* <ChurnChart /> */}</div>
      </div>
    </>
  );
};

export default Dashboard;
