/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { partnerApi } from "src/api";
import DashTeamRow from "src/components/dash/DashTeamRow";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import LoadingTable from "src/components/loading/LoadingTable";

export interface DashTeam {
  id: number;
  companyName: string;
  drivesCount: number;
  propertiesCount: number;
  propertiesAddedCount: number;
}

interface Totals {
  totalDrives: number;
  totalProperties: number;
  totalPropertiesAdded: number;
}

const Dash = () => {
  const [dashTeams, setDashTeams] = useState<DashTeam[]>();
  const [totals, setTotals] = useState<Totals>({
    totalDrives: 0,
    totalProperties: 0,
    totalPropertiesAdded: 0,
  });

  const fetchDashTeams = async () => {
    const resp = await partnerApi.getDashTeams();
    setDashTeams(resp);
    calculateTotals(resp);
  };

  const calculateTotals = (teams: DashTeam[]) => {
    const totals = teams.reduce(
      (acc, team) => {
        acc.totalDrives += team.drivesCount;
        acc.totalProperties += team.propertiesCount;
        acc.totalPropertiesAdded += team.propertiesAddedCount;
        return acc;
      },
      { totalDrives: 0, totalProperties: 0, totalPropertiesAdded: 0 }
    );
    setTotals(totals);
  };

  useEffect(() => {
    fetchDashTeams();
  }, []);

  const [currentDashVersion, setCurrentDashVersion] = useState<string>("");

  const [isFetchingCurrentVersion, setIsFetchingCurrentVersion] =
    useState<boolean>(true);

  const fetchCurrentVersion = async () => {
    try {
      setIsFetchingCurrentVersion(true);
      const res = await partnerApi.getCurrentDashVersion();
      setCurrentDashVersion(res);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsFetchingCurrentVersion(false);
    }
  };

  useEffect(() => {
    fetchCurrentVersion();
  }, []);

  return (
    <>
      <div>
        <div className="mb-2 rounded-lg bg-card-light px-4 py-2 dark:bg-card-dark">
          <div className="flex flex-row items-start justify-start gap-2">
            <span className="text-zinc-500">Current Dash Version:</span>
            <div>
              {isFetchingCurrentVersion ? (
                <LoadingSpinner className={"text-secondary dark:text-theme1"} />
              ) : (
                <span className="font-bold text-zinc-500">
                  {currentDashVersion}
                </span>
              )}
            </div>
          </div>
        </div>

        {dashTeams?.length ? (
          <table className={"table w-full shadow-xl"}>
            <thead>
              <tr className="border-b-2 border-secondary text-secondary">
                <th className="select-none rounded-tl-lg bg-card-light dark:bg-card-dark">
                  ID
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Company Name
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Drives
                </th>
                <th className="select-none bg-card-light  dark:bg-card-dark">
                  Properties Found
                </th>
                <th className="select-none rounded-tr-lg bg-card-light dark:bg-card-dark">
                  Properties Added
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="bg-white dark:bg-card-dark">
                  <div className="flex gap-1">
                    <span className="font-semibold text-zinc-500 dark:text-zinc-300">
                      {dashTeams?.length}
                    </span>
                    <span className="font-light text-zinc-500 dark:text-zinc-300">
                      {dashTeams && dashTeams?.length > 1 ? "Teams" : "Team"}
                    </span>
                  </div>
                </td>
                <td className="bg-white dark:bg-card-dark"></td>
                <td className="bg-white text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {totals.totalDrives}
                </td>
                <td className="bg-white text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {totals.totalProperties}
                </td>
                <td className="bg-white text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {totals.totalPropertiesAdded}
                </td>
              </tr>
              {dashTeams?.map((dashTeam: DashTeam, index: number) => (
                <DashTeamRow dashTeam={dashTeam} key={index} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex w-full items-center justify-center gap-2">
            <LoadingTable label={"Teams"} />
          </div>
        )}
      </div>
    </>
  );
};

export default Dash;
