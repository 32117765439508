/* eslint-disable import/no-anonymous-default-export */
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import {
  Feature,
  Subscription,
  SubscriptionFeature,
} from "src/interfaces/subscriptions";

class SubscriptionApi extends ApiTokenSupport {

  async subscribe(
    plan: string,
    oneTimePaymentAmount: number
  ): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/subscription/subscribe`,
      { plan, oneTimePaymentAmount },
      this.withAuthorization()
    );
    return resp.data;
  }

  async checkSubscription(): Promise<{
    plan: string;
    hasFailedPayments: boolean;
    isDemo?: boolean;
  }> {
    const resp = await axios.get<{
      plan: string;
      hasFailedPayments: boolean;
      isDemo?: boolean;
    }>(`/api/subscription/check`, this.withAuthorization());
    return resp.data;
  }

  async changeSubscription(data: {
    plan: string;
    subscriptionID: number;
    isAnnual?: boolean;
    metadata?: { [name: string]: any };
    costToChange?: number;
    chargeImmediately?: boolean;
  }): Promise<string> {
    const resp = await axios.post<string>(
      "api/subscription/change-subscription",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAllSubscriptions(appID: number): Promise<Subscription[]> {
    const resp = await axios.get<Subscription[]>(
      `/api/subscription/app/${appID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getSubscription(): Promise<Subscription> {
    const resp = await axios.get<Subscription>(
      `/api/subscription/team/`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getByID(subscriptionID: number): Promise<Subscription> {
    const resp = await axios.get<Subscription>(
      `api/subscription/${subscriptionID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getFeatures(): Promise<Feature[]> {
    const resp = await axios.get<Feature[]>(
      `/api/feature/features`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getSubscriptionFeatures(): Promise<SubscriptionFeature[]> {
    const resp = await axios.get<SubscriptionFeature[]>(
      `/api/subscriptionFeatures/team`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAppSubscriptionFeatures(): Promise<SubscriptionFeature[]> {
    const resp = await axios.get<SubscriptionFeature[]>(
      `api/subscriptionFeatures/app`,
      this.withAuthorization()
    );
    return resp.data;
  }

}

export default new SubscriptionApi();
