import React from "react";
import { ToolbarItem } from "../toolbar/ToolbarItem";

interface ReportsToolbarProps {
  isActiveLinks: any;
  handleActive: (str: string) => void;
}

const ReportsToolbar = ({
  isActiveLinks,
  handleActive,
}: ReportsToolbarProps) => {
  return (
    <>
      <div className="tabs tabs-boxed border-[.5px] border-zinc-300 bg-white dark:border-back-dark dark:bg-card-dark">
        <ToolbarItem
          title={"Churn"}
          isActive={isActiveLinks["Churn"]}
          onClick={() => handleActive("Churn")}
        />
        {/* <ToolbarItem
          title={"Continuity"}
          isActive={isActiveLinks["Continuity"]}
          onClick={() => handleActive("Continuity")}
        /> */}
      </div>
    </>
  );
};

export default ReportsToolbar;
