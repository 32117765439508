import React, { useState } from "react";
import useAlert from "src/hooks/useAlert";
import SelectInput from "../fields/SelectInput";
import TextInput from "../fields/TextInput";
import useModal from "src/hooks/useModal";
import { partnerApi, keapApi } from "src/api";
import ButtonBlock from "./ButtonBlock";
import { Subscription } from "src/interfaces/subscriptions";

interface InviteNewTeamModalProps {
  subscriptions: Subscription[];
}

export interface NewTeamFormData {
  appID: number;
  subscriptionID: number;
  keapID: number | null;
  email: string;
}

const InviteNewTeamModal = ({ subscriptions }: InviteNewTeamModalProps) => {
  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const [onSubmitting, setSubmitting] = useState(false);

  const initialFormData = {
    appID: 1,
    subscriptionID: 2,
    keapID: null,
    email: "",
  };

  const [formData, setFormData] = useState<NewTeamFormData>(initialFormData);

  interface FormErrors {
    email: string | null;
    keapID: string | null;
  }

  const initialFormErrors: FormErrors = {
    email: null,
    keapID: null,
  };

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((data: any) => ({
      ...data,
      [name]: value,
    }));
  };

  const validate: any = async (data: any) => {
    const errors: {
      email?: string;
      keapID?: string;
    } = {};
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    if (!data.keapID) {
      errors.keapID = "KeapID is required";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = await validate(formData);
    if (errors.email) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setSubmitting(true);
        const res = await partnerApi.inviteTeam({ ...formData });
        if (res) {
          setAlert({
            display: true,
            message: `${formData.email} was invited`,
            type: "success",
          });
        }
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "There was an error inviting this team",
          type: "error",
        });
      } finally {
        setSubmitting(false);
        setShowModal(false);
      }
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-full">
          <SelectInput
            name="subscriptionID"
            label="Subscription"
            placeholder="Subscription"
            value={formData.subscriptionID.toString()}
            onChange={handleChange}
            options={subscriptions.map((subscription: Subscription) => ({
              label: subscription.name,
              value: subscription.id,
            }))}
          />
        </div>
      </div>
      <div className="mt-3 flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="keapID"
            label="External ID"
            placeholder="External ID"
            value={formData.keapID?.toString()}
            onChange={handleChange}
            error={formErrors.keapID}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            error={formErrors.email}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
      </div>
      <div className="mt-4">
        <ButtonBlock
          submitting={onSubmitting}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={"Invite"}
          disabled={onSubmitting}
        />
      </div>
    </>
  );
};

export default InviteNewTeamModal;
