import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { User } from "src/interfaces/user";
import UserAvatar from "../buttons/UserAvatar";
import admin from "src/api/partner";
import useModal from "src/hooks/useModal";
import useAlert from "src/hooks/useAlert";

interface UserModalProps {
  user: User;
}

const UserModal = ({ user }: UserModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const handleResetPassword = async () => {
    try {
      setShowModal(false);
      await admin.passwordRecovery(user.email);
      setAlert({
        display: true,
        type: "success",
        message: `Password reset email send to ${user.email}`,
      });
    } catch (e: any) {
      console.log(e);
    } finally {
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="mb-2 flex items-center justify-center">
          <UserAvatar user={user} />
        </div>
        <div className="flex w-full items-center justify-center">
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${user.email}`}
            className="w-24"
          >
            <button className="btn-sm btn border-none bg-theme3 text-text-light hover:bg-theme3 dark:text-black dark:text-text-dark">
              <FontAwesomeIcon
                icon={faEnvelope}
                className=" text-md childButton mr-1"
              />
              Email
            </button>
          </a>
          <button
            className="btn-sm btn border-none bg-theme1 text-text-light hover:bg-theme1 dark:text-black dark:text-text-dark"
            onClick={handleResetPassword}
          >
            <FontAwesomeIcon
              icon={faKey}
              className=" text-md childButton mr-1"
            />
            Reset Password
          </button>
        </div>
      </div>
    </>
  );
};

export default UserModal;
