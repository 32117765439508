import React, { useEffect, useState } from "react";
import teamEventsApi from "src/api/teamEvents";
import TeamEvent from "src/interfaces/teamEvent";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useViewport from "src/hooks/useViewport";
import { TeamPageData } from "src/interfaces/TeamPageData";
import TableFooter from "../table/TableFooter";

interface TeamEventsTableProps {
  team: TeamPageData;
}

const TeamEventsTable = ({ team }: TeamEventsTableProps) => {
  const [events, setEvents] = useState<TeamEvent[]>([]);

  const { isMobile } = useViewport();

  const fetchEvents = async () => {
    const response = await teamEventsApi.getByTeamID(team.team.id);
    setEvents(response);
  };

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  return (
    <>
      <table
        className={
          isMobile
            ? "table-compact block table w-full shadow-xl "
            : "block table w-full rounded-xl shadow-xl"
        }
      >
        <thead>
          <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
            <th className="sticky top-[115px] rounded-tl-lg bg-card-light text-center  text-base dark:bg-card-dark lg:top-0">
              Date
            </th>
            <th className="sticky top-[115px] rounded-tr-lg bg-card-light text-center  text-base dark:bg-card-dark lg:top-0">
              Event
            </th>
          </tr>
        </thead>
        <tbody>
          {events.length ? (
            events
              ?.sort(
                (a: TeamEvent, b: TeamEvent) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              )
              .map((event: TeamEvent) => (
                <tr key={event.id} className="bg-card-light dark:bg-card-dark">
                  <td className="bg-card-light text-center font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
                    {parseMonthDayYear(event.createdAt)}
                  </td>
                  <td className="bg-card-light text-center font-medium text-text-dark dark:bg-card-dark dark:text-text-light">
                    {event.eventID}
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td
                className="bg-card-light text-center text-xs font-medium text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                colSpan={6}
              >
                No Events for this Team yet
              </td>
            </tr>
          )}
          <TableFooter colSpan={6} />
        </tbody>
      </table>
    </>
  );
};

export default TeamEventsTable;
