import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "src/assets/images/SendFuse_Logo_Light.png";

import ThemeSwitch from "../../theme/ThemeSwitch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";

interface HeaderBarNavProps {
  props?: any;
}

const HeaderBarNav = ({ props }: HeaderBarNavProps) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header className="items-space-between flex-no-wrap fixed z-30 flex min-w-full justify-between bg-white p-6 dark:bg-card-dark">
      {navOpen && (
        <div>
          {/* <div
            onClick={() => setNavOpen(!navOpen)}
            className="absolute bottom-0 left-0 top-0 z-40 min-h-screen w-1/3 bg-title-text-light opacity-25 md:w-7/12"
          ></div>
          <div
            id="drawer-nav"
            className="absolute bottom-0 right-0 top-0 z-40 min-h-screen w-2/3 bg-white dark:bg-card-dark md:w-5/12"
          >
            <div className="items-left flex min-w-full">
              <ul className="flex min-w-full list-none flex-col">
                <li className="mt-2 items-center">
                  <Link to="/dashboard">
                    <div
                      onClick={() => setNavOpen(!navOpen)}
                      className={
                        window.location.href.indexOf("/dashboard") !== -1
                          ? "mb-3 bg-gradient-to-b from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark"
                          : "hover:bg-card-blend-light mb-3 rounded-lg px-10 py-3 dark:hover:bg-back-dark"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faGaugeHigh}
                        className={
                          window.location.href.indexOf("/dashboard") !== -1
                            ? "md:text-2xl mr-1.5 text-xl text-white  dark:text-black"
                            : "md:text-2xl mr-1.5 text-xl text-icon-light dark:text-icon-dark"
                        }
                      />
                      <span
                        className={
                          window.location.href.indexOf("/dashboard") !== -1
                            ? "md:text-2xl text-xl font-bold text-white  dark:text-black"
                            : "md:text-2xl text-xl font-bold text-icon-light dark:text-icon-dark "
                        }
                      >
                        {" "}
                        Dashboard
                      </span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      )}

      <div className="flex h-8 w-full items-center justify-around">

        <div className="mr-14">
          <img src={logo} width="200" alt="SendFuse Logo" />
        </div>
        {/* <div className="flex">
          <button>
            <FontAwesomeIcon
              icon={faBars}
              className="text-periwinkle h-6 md:h-8"
              onClick={() => setNavOpen(!navOpen)}
            />
          </button>
        </div> */}
      </div>
    </header>
  );
};

export default HeaderBarNav;
