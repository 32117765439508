import useAdminContext from "src/hooks/private/usePartnerContext";

const Username = () => {
  const { partner } = useAdminContext();

  return (
    <>
      <button className="btn-ghost btn text-primary hover:bg-hover-back-light hover:dark:bg-hover-back-dark">
        {`${partner?.firstName} ${partner?.lastName}`}
      </button>
    </>
  );
};

export default Username;
