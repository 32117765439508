import React from "react";
import ThemeSwitch from "src/components/theme/ThemeSwitch";
// import SearchBar from "../desktop/SearchBar";
import Username from "../../buttons/Username";
import useAlert from "src/hooks/useAlert";
import { motion, AnimatePresence } from "framer-motion";
import Alert from "src/components/alert/Alert";
import useModal from "src/hooks/useModal";

interface HeaderNavProps {
  props?: any;
}

const HeaderNav = ({ props }: HeaderNavProps) => {
  const { alert, setAlert } = useAlert();

  const { showModal } = useModal();

  return (
    <header className="fixed left-72 right-0 top-0 z-40 flex h-16 columns-1 flex-col bg-back-light dark:bg-back-dark">
      <div className="flex w-full items-center justify-center bg-back-light px-6 dark:bg-back-dark">
        <div className="mt-1.5 flex w-full items-center justify-between">
          {/* <SearchBar /> */}
          <div className="flex w-full items-center justify-end">
            <ThemeSwitch />
            <Username />
          </div>
        </div>
      </div>
      {!showModal && (
        <div className="px-[22px]">
          <AnimatePresence>
            {alert.display && (
              <motion.div
                initial={{ y: -20, opacity: 0.8 }}
                transition={{ duration: 0.15 }}
                animate={{ y: 2, opacity: 1 }}
                exit={{ y: -20, opacity: 0.5 }}
              >
                <Alert
                  alert={alert}
                  _className={"h-[30px] lg:h-[44px]"}
                  onClose={() =>
                    setAlert({
                      display: false,
                      type: alert.type,
                      message: alert.message,
                    })
                  }
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </header>
  );
};

export default HeaderNav;
