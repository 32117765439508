import { ReactNode } from "react";
/***** Navigation ******/
import HeaderNav from "../components/navigation/desktop/HeaderNav";
import SideNav from "../components/navigation/desktop/SideNav";
import HeaderNavMobile from "../components/navigation/mobile/HeaderNavMobile";

/***** Hooks *****/
import useViewport from "../hooks/useViewport";
// import useAlert from "src/hooks/useAlert";

interface PrivateLayoutProps {
  children: ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  let { width } = useViewport();
  let isMobile = width <= 1024;

  // const { alert, clearAlert } = useAlert();

  return (
    <>
      {isMobile ? <HeaderNavMobile /> : <SideNav />}
      {!isMobile && <HeaderNav />}
      {isMobile ? (
        <div className=" min-h-screen min-w-full overflow-x-hidden bg-back-light dark:bg-back-dark">
          {/* <div className="relative top-40 mx-3">
            <Alert alert={alert} onClose={clearAlert} />
          </div> */}
          <div className="z-10 mx-3 mt-[86px] overflow-auto">{children}</div>
        </div>
      ) : (
        <>
          <div className="fixed bottom-0 left-72 right-0 top-16 overflow-auto overflow-x-hidden overscroll-none bg-back-light px-6 py-0.5 dark:bg-back-dark">
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default PrivateLayout;
