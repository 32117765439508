import React, {
  useCallback,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";
import useAuth from "src/hooks/useAuth";
import useAlert from "src/hooks/useAlert";
import partnerApi from "src/api/partner";
import { Partner } from "src/interfaces/partner";

interface PartnerContextInterface {
  partner: Partner | null | undefined;
  fetchPartner: () => void;
  teams: any[] | null | undefined;
  fetchTeams: () => void;
  isLoading: boolean;
}

const PartnerContextDefaults = {
  partner: null,
  fetchPartner: () => {},
  teams: [],
  fetchTeams: () => {},
  isLoading: false,
};

export const PartnerContext = React.createContext<PartnerContextInterface>(
  PartnerContextDefaults
);

interface PartnerProviderProps {}

export const PartnerProvider = ({
  children,
}: PropsWithChildren<PartnerProviderProps>) => {
  const { currentPartner } = useAuth();
  const partnerID = currentPartner;
  const { setAlert } = useAlert();

  const [partner, setPartner] = useState<Partner>();
  const [teams, setTeams] = useState<any[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleError = useCallback(
    (err: any) => {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    },
    [setAlert]
  );

  const fetchPartner = useCallback(async () => {
    if (partnerID) {
      try {
        const partner = await partnerApi.getPartner(partnerID);
        setPartner(partner);
      } catch (err) {
        handleError(err);
      }
    }
  }, [handleError, partnerID]);

  const fetchTeams = useCallback(async () => {
    try {
      setIsLoading(true);
      const teams = await partnerApi.getTeams();
      setTeams(teams);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [handleError]);

  useEffect(() => {
    fetchPartner();
  }, [fetchPartner]);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  return (
    <PartnerContext.Provider
      value={{
        partner,
        fetchPartner,
        teams,
        fetchTeams,
        isLoading,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerContext;
