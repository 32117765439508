/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { partnerApi } from "src/api";
import { TokenContext } from "src/contexts/TokenGuardContext";
import useAlert from "./useAlert";
import { useNavigate } from "react-router-dom";

const useTokenContext = () => useContext(TokenContext);

const TOKEN_KEY = "register_partner_token";

const useTokenGuard = (purpose?: string, tokenKey?: string) => {
  const { token } = useParams();

  const { tokenData, email, setState } = useTokenContext();

  const { setAlert } = useAlert();

  const navigate = useNavigate();

  const [storedToken, setStoredToken] = useState({
    token,
    purpose,
  });
  const [onChecking, setOnChecking] = useState(true);
  const [redirection, setRedirectTo] = useState<string>("");

  const verifyToken = useCallback(
    async (mainPurpose) => {
      if (!storedToken && mainPurpose) {
        setOnChecking(false);
        return;
      }

      const { token, purpose } = storedToken;

      if (!token || !purpose || tokenData) {
        return;
      }

      let result = null;

      try {
        switch (purpose) {
          case "invite-partner":
            result = await partnerApi.verifyPartnerInvitation(token);
            break;
        }
        if (result) {
          const { email } = result;

          setState(email, purpose, { ...result, token });
        }
      } catch (err: any) {
        navigate("/invalid-token");
        setAlert({
          display: true,
          message: err?.message || err,
          type: "error",
        });
      } finally {
        setOnChecking(false);
      }
    },
    [storedToken, tokenData, setState]
  );

  const checkExistingEmailInLocalStorage = useCallback(() => {
    const localToken = localStorage.getItem(tokenKey || TOKEN_KEY);

    if (!token && localToken) {
      return setStoredToken(JSON.parse(localToken));
    }
    if (token && purpose) {
      localStorage.setItem(
        tokenKey || TOKEN_KEY,
        JSON.stringify({ token, purpose })
      );
    }
  }, [token, purpose]);

  useEffect(() => {
    checkExistingEmailInLocalStorage();
  }, [checkExistingEmailInLocalStorage]);

  useEffect(() => {
    verifyToken(purpose);
  }, [verifyToken]);

  return {
    tokenData,
    email,
    token,
    onChecking,
    storedToken,
    redirection,
  };
};

export default useTokenGuard;
