import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { partnerApi } from "src/api";
import AuthButton from "src/components/buttons/AuthButton";
import PasswordInput from "src/components/fields/PasswordInput";

import useAlert from "src/hooks/useAlert";
import useTokenGuard from "src/hooks/useTokenGuard";
import AuthLayout from "src/layouts/AuthLayout";

const SetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { setAlert } = useAlert();

  const { tokenData } = useTokenGuard();

  console.log(tokenData);

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setError({ password: "", confirmPassword: "" });
    setFormData({ ...formData, [name]: value });
  };

  const validate = (data: any) => {
    const errors: {
      password?: string;
      confirmPassword?: string;
    } = {};
    if (!data.password) {
      errors.password = "Please set a password";
    }
    if (!data.confirmPassword || data.password !== data.confirmPassword) {
      errors.confirmPassword = "Password not matched";
    }
    return errors;
  };

  const handleSubmit = async () => {
    const errors: any = validate(formData);
    if (errors.password || errors.confirmPassword) {
      return setError(errors);
    }

    try {
      setSubmitting(true);
      const jwt = await partnerApi.setPassword(
        formData.password,
        tokenData?.token || ""
      );

      if (jwt) {
        localStorage.removeItem("register_partner_token")
        localStorage.setItem("x-partner-access-token", jwt);
        navigate("/dashboard");
        window.location.reload();
      }
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <AuthLayout title="Set Password">
      <form>
        <div className="relative mb-3 w-full">
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
          />
        </div>
        <div className="relative mb-3 w-full">
          <PasswordInput
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            error={errors.confirmPassword}
          />
        </div>
        <div className="mt-6 text-center">
          <AuthButton
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
          >
            Submit
          </AuthButton>
        </div>
      </form>
    </AuthLayout>
  );
};

export default SetPassword;
