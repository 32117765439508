import { PropsWithChildren, ReactNode } from "react";
import Alert from "src/components/alert/Alert";
import useAlert from "src/hooks/useAlert";
import logoLight from "src/assets/images/SendFuse_Logo_Light.png";
import { Outlet } from "react-router-dom";

interface AuthLayoutProps {
  title: string;
  bottomComponent?: ReactNode;
  fullWidth?: boolean;
}

const AuthLayout = ({
  title,
  children,
  bottomComponent,
  fullWidth,
}: PropsWithChildren<AuthLayoutProps>) => {
  const { alert, clearAlert } = useAlert();
  return (
    <main>
      <section className="absolute w-full h-full bg-back-dark overflow-hidden">
        <div className="container mx-auto px-3 h-full">
          <div className="flex items-center justify-center h-full">
            <div
              className={
                fullWidth ? "w-full" : "xs:w-11/12 sm:w-9/12 md:w-7/12 lg:w-6/12 xl:w-5/12"
              }
            >
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
                <div className="rounded-t-lg mb-0 py-6 bg-back-light border-b-primary border-2">
                  <div className="text-center mb-1">
                    <h1 className="text-back-dark text-xxxl font-bold w-full flex items-center justify-center">
                      <img src={logoLight} alt="logo" className="w-32 md:w-60" />
                      <div className="pt-1 md:pt-3 ml-2.5">
                        <p className="text-primary font-bold italic text-base md:text-xxxl">
                          Partner
                        </p>
                      </div>
                    </h1>
                  </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-3 bg-back-light rounded-b-lg">
                  <div className="mb-3 ">
                    {alert.display && (
                      <Alert
                        alert={alert}
                        onClose={clearAlert}
                        removeBackground={true}
                      />
                    )}
                  </div>
                  {children || <Outlet />}
                </div>
              </div>
              {bottomComponent}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AuthLayout;
