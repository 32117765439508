import React, { useEffect, useState } from "react";
import TextInput from "../fields/TextInput";
import useModal from "src/hooks/useModal";
import SelectInput from "../fields/SelectInput";
import States from "src/data/States";
import { partnerApi } from "src/api";
import StripeForm from "src/components/stripe/StripeForm";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { withStripeProvider } from "src/hooks/useStripeOptions";
import useAlert from "src/hooks/useAlert";
import generatePassword from "src/helpers/generatePassword";
import ButtonBlock from "./ButtonBlock";
import { Subscription } from "src/interfaces/subscriptions";
import { Partner } from "src/interfaces/partner";
import usePartnerContext from "src/hooks/private/usePartnerContext";

interface NewTeamOnboardingModalProps {
  partner: Partner;
  subscriptions: Subscription[];
  _keapID?: number;
  _email?: string;
  callBack?: () => {};
}

const NewTeamOnboardingModal = ({
  partner,
  subscriptions,
  _keapID,
  _email,
  callBack,
}: NewTeamOnboardingModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const { fetchTeams } = usePartnerContext();

  const stripe = useStripe();

  const elements = useElements();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const createCardToken = async (nameOnCard: string): Promise<any> => {
    if (!stripe || !elements) {
      throw new Error("Stripe not loaded");
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) throw new Error("Stripe element not found");

    const { error, token } = await stripe.createToken(cardElement, {
      name: nameOnCard,
    });
    if (error) throw new Error(error.message);

    return token;
  };

  interface OnboardingFormData {
    appID: number;
    subscriptionID: number;
    keapID: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    companyWebsite: string;
    returnAddressLine1: string;
    returnAddressLine2: string;
    returnAddressCity: string;
    returnAddressState: string;
    returnAddressZip: string;
    returnPhoneNumber: string;
    isAnnualSubscription: string;
  }

  const initialFormData: OnboardingFormData = {
    appID: partner.appID,
    subscriptionID: subscriptions[0].id,
    keapID: _keapID ? _keapID.toString() : "",
    firstName: "",
    lastName: "",
    email: _email ? _email : "",
    companyName: "",
    companyWebsite: "",
    returnAddressLine1: "",
    returnAddressLine2: "",
    returnAddressCity: "",
    returnAddressState: "",
    returnAddressZip: "",
    returnPhoneNumber: "",
    isAnnualSubscription: "monthly",
  };

  const [formData, setFormData] = useState<OnboardingFormData>(initialFormData);

  interface FormErrors {
    subscription: string | null;
    keapID: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    companyName: string | null;
    companyWebsite: string | null;
    returnAddressLine1: string | null;
    returnAddressLine2: string | null;
    returnAddressCity: string | null;
    returnAddressState: string | null;
    returnAddressZip: string | null;
    returnPhoneNumber: string | null;
  }

  const initialFormErrors: FormErrors = {
    subscription: null,
    keapID: null,
    firstName: null,
    lastName: null,
    email: null,
    companyName: null,
    companyWebsite: null,
    returnAddressLine1: null,
    returnAddressLine2: null,
    returnAddressCity: null,
    returnAddressState: null,
    returnAddressZip: null,
    returnPhoneNumber: null,
  };

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const isInputNotANumber = (string: string) => {
    return isNaN(Number(string));
  };

  const [isAnnualSelectable, setIsAnnualSelectable] = useState<boolean>(false);

  useEffect(() => {
    const selectedSubscription = subscriptions.find(
      (sub: Subscription) =>
        sub.id === parseInt(formData.subscriptionID.toString())
    );
    if (selectedSubscription) {
      setIsAnnualSelectable(selectedSubscription.hasAnnual);
      if (!selectedSubscription.hasAnnual) {
        setFormData((data) => ({
          ...data,
          isAnnualSubscription: "monthly",
        }));
      }
    }
  }, [formData.subscriptionID, subscriptions]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "returnPhoneNumber" && isInputNotANumber(e.nativeEvent.data)) {
      return;
    }
    if (
      name === "returnPhoneNumber" &&
      value.replace(/\D/g, "").length > 10 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      return;
    }
    if (name === "returnAddressZip" && isInputNotANumber(e.nativeEvent.data)) {
      return;
    }
    if (
      name === "returnAddressZip" &&
      value.replace(/\D/g, "").length > 5 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      return;
    }

    setFormData((data: any) => {
      const updatedData = { ...data, [name]: value };

      if (name === "subscriptionID") {
        const selectedSubscription = subscriptions.find(
          (sub) => sub.id === parseInt(value)
        );
        if (selectedSubscription && !selectedSubscription.hasAnnual) {
          updatedData.isAnnualSubscription = "monthly";
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = await validate(formData);
    if (
      errors.subscription ||
      errors.keapID ||
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.companyName ||
      errors.companyWebsite ||
      errors.returnAddressLine1 ||
      errors.returnAddressLine2 ||
      errors.returnAddressCity ||
      errors.returnAddressState ||
      errors.returnAddressZip ||
      errors.returnPhoneNumber
    ) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      let cardToken = null;
      try {
        setSubmitting(true);
        const {
          appID,
          email,
          keapID,
          subscriptionID,
          firstName,
          lastName,
          companyName,
          companyWebsite,
          returnAddressLine1,
          returnAddressLine2,
          returnAddressCity,
          returnAddressState,
          returnAddressZip,
          returnPhoneNumber,
          isAnnualSubscription,
        } = formData;
        const nameOnCard = `${formData.firstName} ${formData.lastName}`;
        cardToken = await createCardToken(nameOnCard);
        const res = await partnerApi.onboardTeam({
          appID,
          email,
          subscriptionID: parseInt(subscriptionID.toString()),
          firstName,
          lastName,
          companyName,
          companyWebsite,
          returnAddressLine1,
          returnAddressLine2,
          returnAddressCity,
          returnAddressState,
          returnAddressZip,
          returnPhoneNumber,
          nameOnCard,
          keapID: parseInt(keapID),
          billingAddressLine1: returnAddressLine1,
          billingAddressLine2: returnAddressLine2,
          billingAddressCity: returnAddressCity,
          billingAddressState: returnAddressState,
          billingAddressZip: returnAddressZip,
          isLeader: true,
          password: generatePassword(),
          paymentMethod: cardToken,
          adminOnboard: true,
          isAnnualSubscription:
            isAnnualSubscription === "monthly" ? false : true,
        });
        if (!res.success) {
          setAlert({
            display: true,
            message: res.error,
            type: "error",
          });
        } else {
          setShowModal(false);
          setAlert({
            display: true,
            message: `Successfully onboarded ${formData.companyName}`,
            type: "success",
          });
        }
      } catch (e: any) {
        console.warn(e.message);
        setAlert({
          display: true,
          message: e.message,
          type: "error",
        });
      } finally {
        setSubmitting(false);
        fetchTeams();
        callBack && callBack();
      }
    }
  };

  const validate: any = async (data: any) => {
    const errors: {
      subscription?: string;
      keapID?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      companyName?: string;
      companyWebsite?: string;
      returnAddressLine1?: string;
      returnAddressLine2?: string;
      returnAddressCity?: string;
      returnAddressState?: string;
      returnAddressZip?: string;
      returnPhoneNumber?: string;
      nameOnCard?: string;
      billingAddressLine1?: string;
      billingAddressLine2?: string;
      billingAddressCity?: string;
      billingAddressState?: string;
      billingAddressZip?: string;
    } = {};
    if (!data.email) {
      errors.email = "An email address is required";
    }
    if (!data.companyName) {
      errors.companyName = "Company Name is required";
    }
    // if (data.companyWebsite && !isValidURL(data.companyWebsite)) {
    //   errors.companyWebsite = "Please enter a correct website URL";
    // }
    if (!data.returnAddressLine1) {
      errors.returnAddressLine1 = "Address is required";
    }
    if (!data.returnAddressCity) {
      errors.returnAddressCity = "City is required";
    }
    if (!data.returnAddressState) {
      errors.returnAddressState = "State is required";
    }
    if (!data.returnAddressZip) {
      errors.returnAddressZip = "Zipcode is required";
    }
    if (!data.returnPhoneNumber) {
      errors.returnPhoneNumber = "Phone Number is required";
    } else if (data.returnPhoneNumber.replace(/\D/g, "").length !== 10) {
      errors.returnPhoneNumber = "Phone Number must be 10 digits";
    }
    return errors;
  };

  const parsePhone = (phone: string) => {
    if (!phone) return "";
    if ((phone || "").length === 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else {
      return phone.replace(/\D/g, "");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-full">
          <SelectInput
            name="subscriptionID"
            label="Subscription"
            placeholder="Subscription"
            value={formData.subscriptionID.toString()}
            onChange={handleChange}
            error={formErrors.subscription}
            options={subscriptions.map((subscription: Subscription) => ({
              label: subscription.name,
              value: subscription.id,
            }))}
          />
        </div>
      </div>
      <div className="mt-3 flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="keapID"
            label="External ID"
            placeholder="External ID"
            value={formData.keapID}
            onChange={handleChange}
            error={formErrors.keapID}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="w-1/2">
          <SelectInput
            name="isAnnualSubscription"
            label="Subscription Type"
            placeholder="Subscription"
            value={formData.isAnnualSubscription}
            onChange={handleChange}
            error={formErrors.subscription}
            options={[
              {
                label: "Monthly",
                value: "monthly",
              },
              {
                label: "Annual",
                value: "annual",
                disabled: !isAnnualSelectable,
              },
            ]}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.email}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="returnPhoneNumber"
            label="Phone Number"
            placeholder="Phone Number"
            value={parsePhone(formData.returnPhoneNumber)}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnPhoneNumber}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.firstName}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.lastName}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="companyName"
            label="Company Name"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.companyName}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="companyWebsite"
            label="Company Website"
            placeholder="Company Website"
            value={formData.companyWebsite}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.companyWebsite}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="returnAddressLine1"
            label="Address Line 1"
            placeholder="Address Line 2"
            value={formData.returnAddressLine1}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressLine1}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="returnAddressLine2"
            label="Address Line 2"
            placeholder="Address Line 2"
            value={formData.returnAddressLine2}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressLine2}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-5/12">
          <TextInput
            name="returnAddressCity"
            label="City"
            placeholder="City"
            value={formData.returnAddressCity}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressCity}
          />
        </div>
        <div className="w-2/12">
          <SelectInput
            name="returnAddressState"
            label="State"
            value={formData.returnAddressState}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressState}
            options={States}
          />
        </div>
        <div className="w-5/12">
          <TextInput
            name="returnAddressZip"
            label="Zipcode"
            placeholder="Zipcode"
            value={formData.returnAddressZip}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressZip}
          />
        </div>
      </div>
      <div className="relative my-4 flex w-full">
        <StripeForm />
      </div>
      <div className="mt-4">
        <ButtonBlock
          submitting={submitting}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
          submitLabel={"Onboard"}
          disabled={submitting}
        />
      </div>
    </>
  );
};

export default withStripeProvider(NewTeamOnboardingModal);
