import { PropsWithChildren } from "react";

/***** Context Provider *****/
import { ModalProvider } from "src/contexts/ModalContext";
import { PartnerProvider } from "src/contexts/private/PartnerContext";
import { SubscriptionProvider } from "src/contexts/private/SubscriptionContext";

interface PrivateProviderProps {}

const PrivateProvider = ({
  children,
}: PropsWithChildren<PrivateProviderProps>) => {
  return (
    <PartnerProvider>
      <SubscriptionProvider>
        <ModalProvider>{children}</ModalProvider>
      </SubscriptionProvider>
    </PartnerProvider>
  );
};

export default PrivateProvider;
