import { ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames";

interface FloatingInputProps {
  name: string;
  label?: string | ReactNode;
  type?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  prefix?: ReactNode;
  hasComma?: boolean;
  rows?: number;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
}

const FloatingInput = ({
  name,
  label,
  error,
  type,
  prefix,
  errorProps,
  labelProps,
  inputProps,
  ...props
}: FloatingInputProps) => {
  const errorClassName = classNames(
    "absolute bg-back-light dark:bg-base-100 text-error text-2xs md:text-xs italic left-4 px-1",
    {
      "top-[43px]": type !== "textarea",
      "top-[225px]": type === "textarea",
    },
    errorProps?.className
  );

  const _inputProps = {
    id: name,
    className: classNames(
      "block w-full bg-transparent rounded-lg appearance-none dark:text-text-light focus:outline-none focus:ring-0 peer",
      {
        "px-2.5 pb-2.5 pt-3.5  text-gray-900 border-[2.5px] border-error  dark:border-error dark:focus:border-error focus:border-error":
          prefix && error,
        "pl-5 pb-2.5 pt-3.5 font-semibold border-[2.5px] border-secondary dark:border-secondary dark:focus:border-secondary focus:border-secondary":
          prefix && !error,
        "px-2.5 pb-2.5 pt-4 text-gray-900 border border-error dark:border-error dark:focus:border-error focus:border-error":
          !prefix && error,
        "pl-5 pb-2.5 pt-4 font-semibold border border-secondary dark:border-secondary dark:focus:border-secondary focus:border-secondary":
          !prefix && !error,
        "text-[17px]": type !== "textarea",
      },
      inputProps?.className
    ),
    style: {
      outline: "none",
    },
    type,
    name,
    ...props,
  };

  const input = (
    <>
      {type === "textarea" ? (
        <textarea {..._inputProps}>{props.value}</textarea>
      ) : (
        <input {..._inputProps} />
      )}
      <label
        htmlFor={name}
        className={classNames(
          "absolute left-2.5 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-back-light px-2 text-sm font-medium text-text-dark duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-3 peer-focus:text-text-dark dark:bg-base-100 dark:text-text-light dark:peer-focus:text-text-light",
          labelProps?.className
        )}
        {...labelProps}
      >
        {label}
      </label>
      {error && (
        <p {...errorProps} className={errorClassName}>
          {error}
        </p>
      )}
    </>
  );

  return (
    <>
      {prefix ? (
        <div className="relative flex items-center justify-center">
          <div className="absolute -left-[4px] top-[15px] z-50 bg-back-light dark:bg-base-100">
            {prefix}
          </div>
          {input}
        </div>
      ) : (
        <div className="relative">{input}</div>
      )}
    </>
  );
};

export default FloatingInput;
